
.character-chip {
  width: 60px;
  height: 60px;
  border: 1px solid black;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  cursor: grab;
  background-color: bisque;
  .correct-place {
    background-color: lightgreen;
  }
  .dragging {
    opacity: 0.5;
  }
}
